import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useMatch, useParams } from 'react-router-dom';
import StoreForm from '../Components/Forms/StoreForm';
import { AREA_PREFIX, FLEET_PREFIX, MERCHANT_PREFIX, STORE_PREFIX, TEAM_PREFIX } from '../utils/Constants';
import ExpenseForm from '../Components/Forms/ExpenseForm';

const AddStaff = () => {
    let {useraction, id,user_id,year,month} = useParams()

    let title = ""
    let breadcrumbData = []
    switch(useraction) {
        case "create":
            title = "Add Expense"
            breadcrumbData.push({title: "Add Expense", link: null})
            break
        case "edit":
            title = "Edit Expense"
            breadcrumbData.push({title: "Expense", link: `${TEAM_PREFIX}`}, {title: "Edit Expense", link: null})
            break
        case "read":
            title = "Read Expense"
            breadcrumbData.push({title: "Expense", link: `${TEAM_PREFIX}`}, {title: "Read Expense", link: null})
            break
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="page-title">{title}</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                {
                                    breadcrumbData.map((obj, index) => {
                                        if(obj.link){
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item"><Link to={`${obj.link}`}>{obj.title}</Link></li>
                                            )
                                        }else {
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item active">{obj.title}</li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <ExpenseForm member_id={id} user_id={user_id} year={year} month={month} crud={useraction} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddStaff