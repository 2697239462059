import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { LICENSE_IMG, TEAM_PREFIX, TOAST_MSG_TYPE, getConstantData } from '../../utils/Constants';
import { Select as AntSelect,DatePicker } from 'antd';
import Select from 'react-select';
import useAuth from '../../hooks/useAuth';
import { useFleetList } from '../../hooks/FleetHooks';
import ImageUpload from '../ImageUpload';
import { teamService } from '../../Services/TeamService';
import countryCodes from '../../utils/countryCodes';
import moment from 'moment';
const initialState = {id: 0, att_in_time: undefined, att_out_time: undefined, att_exp_amount: "", attdate: undefined,att_note: "" }
const TeamForm = (props) => {
	console.log(props,"props")
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({paginated: false, limit: 0, offset: 0, search: "", field: "id", sort: "ASC"})
    const {fleetData, loading, error, total} = useFleetList(axiosPrivate, params)
    const [formState, setFormState] = useState({...initialState,att_user_id:props.user_id})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        if(props.member_id) {
            teamService.getAttendance(axiosPrivate, props.member_id).then(res => {
                if(res.data.attendanceDetailsByID) {
                    const data = res.data.attendanceDetailsByID.details
					console.log(data)
                    setFormState({
                        ...data,
						id: props.member_id,
                        att_in_time: data.att_in_time ? data.att_in_time : undefined,
                        att_out_time: data.att_out_time ? data.att_out_time : undefined,
                        att_exp_amount: data.att_exp_amount,
						attdate: data.att_date ? moment(data.att_date) : undefined,
						att_note: data.att_note,
						att_user_id: props.user_id
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${TEAM_PREFIX}`)
                }
            })
        }else {
            setShowLoader(false)
        }
    }, [props.member_id])
	
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }    
    const onChangeDate = (value, dateString) => {
		console.log("dateString",dateString)
        setFormState({...formState, attdate: moment(dateString)})
    }
	const onChangeInTime = (value, dateString) => {
        setFormState({...formState, att_in_time: dateString})
    }
	const onChangeOutTime = (value, dateString) => {
        setFormState({...formState, att_out_time: dateString})
    }

    const validateForm = () => {
		console.log(formState)
        if(!formState.att_in_time) {
            showToast("Please Choose In Time and date", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(!formState.att_out_time) {
            showToast("Please Out Time and date", TOAST_MSG_TYPE.ERROR)
            return false
        }
        return true
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(!validateForm()) {
            return
        }
        let param = {
            id: parseInt(formState.id),
            att_date: moment(formState.attdate).format("yy-MM-DD"),
            att_in_time: formState.att_in_time,
            att_out_time: formState.att_out_time,
            att_exp_amount: parseFloat(formState.att_exp_amount),
			att_note: formState.att_note,
			att_user_id: parseInt(formState.att_user_id)
        }
		console.log(param)
        
        const apiPromise = teamService.updateAttendance(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.userAttendanceAdmin) {
                showToast(props.crud === "create" ? "Updated Successfully!" : "Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`/attendance/${props.user_id}/${props.year}/${props.month}`)
            }
        })
    }
    
    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Date *</label>							
							<DatePicker 
                                placeholder="Select Start Date" 
                                className="form-control" 
                                onChange={onChangeDate} 
                                value={formState.attdate || ""}
                            />
						</div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>In Time *</label>
							<DatePicker
							  showTime
							  className='col-md-12'
							  value={moment(formState.att_in_time)}
							  format="MM/DD/YYYY h:mm A"
							  placeholder="Select Time"
							  onChange={onChangeInTime}
							/>
						</div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>Out Time *</label>
							<DatePicker
							  showTime
							  className='col-md-12'
							  value={moment(formState.att_out_time)}
							  format="MM/DD/YYYY h:mm A"
							  placeholder="Select Time"
							  onChange={onChangeOutTime}
							/>
						</div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>Today Total Exp Amount *</label>
                            <input type="text" className='form-control' name='att_exp_amount' value={formState.att_exp_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-8'>
                        <div className='form-group'>
                            <label>Note *</label>

                            <textarea 
							className='form-control'
							rows={6} 
							value={formState.att_note} 
							name='att_note' 
							onChange={onChangeField}>
							</textarea>  </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default TeamForm