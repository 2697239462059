import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal, Select, Table, Switch } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useBannerList } from '../hooks/BannerHooks';
import { useCityList } from '../hooks/RouteHooks';
import { useOrderList } from '../hooks/OrderHooks';
import moment from 'moment';
import { API_BASE_URL, ORDER_PREFIX, TOAST_MSG_TYPE } from '../utils/Constants';
import { useOrdersFilterFleet } from '../hooks/FleetHooks';
import { orderService } from '../Services/OrderService';
import { Collapse } from 'antd';
const { Panel } = Collapse;
const DELIVERYTYPE = [
    {label: "Bazaaro Orders", value: "Bazaaro"},
    {label: "CanadaPost Orders", value: "CanadaPost"}
]

const OrderList = () => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    const [cityParam, seCityParams] = useState({paginated: false, offset:0, limit: 10, field: "area_name", sort: "ASC", search: ""})
    const {citiesData, loading, error, total} = useCityList(axiosPrivate, cityParam)
    const [fleetParams, setFleetParams] = useState({counter: 0, limit: 10, offset: 0, search: "", field: "id", sort: "ASC", paginated: false})
    const {fleetData, loading: fLoading} = useOrdersFilterFleet(axiosPrivate, fleetParams, auth)
    const [params, setParams] = useState({driver_id:0,paginated: true, limit: 20, offset: 0, searchTerm: "",delivery_service: "", searchStatus: localStorage.getItem("searchStatus") ? Number(localStorage.getItem("searchStatus")) : -1, searchCity:localStorage.getItem("searchCity") || "", searchDate: localStorage.getItem("searchDate") || "", sortBy: "id", sortOrder: "DESC", searchDeliveryFleetId: Number(localStorage.getItem("searchDeliveryFleetId")) || null,is_important_search: localStorage.getItem("is_important_search") ? Number(localStorage.getItem("is_important_search")) : 0})
    const {orderData, loading: orderLoading, total: orderTotal,total_amount,total_refund_amount,total_refund_request,important_orders,driver_list} = useOrderList(axiosPrivate, params)
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Order',
                dataIndex: 'order_id',
            },
            {
                title: 'Date',
                dataIndex: 'createdAt',
                render: (text) => {
                    return moment.unix(text/1000).format("YYYY-MM-DD")
                }
            },
            {
                title: 'Ship.Phone No',
                dataIndex: '',
                render: (text, item) => {
                    return `${item.shipping_address.phone_code}-${item.shipping_address.phone}`
                }
            },
            {
                title: 'Ship.Slot',
                dataIndex: '',
                render: (text, item) => {
                    return `${item.shippping_slot.shipping_date} | ${item.shippping_slot.enter_time}-${item.shippping_slot.exit_time}`
                }
            },
			{
                title: 'Delivery',
                dataIndex: 'delivery_service'
            },
            {
                title: 'Fleet',
                dataIndex: 'fleet_licence_number'
            },
            {
                title: 'Total Items',
                dataIndex: 'total_items'
            },
            {
                title: 'Total Price',
                dataIndex: 'total_paid_amount'
            },
            {
                title: 'City',
                dataIndex: '',
                render: (text, item) => {
                    return `${item.shipping_address.city}`
                }
            },
			{
                title: 'Customer/Orders',
                dataIndex: '',
                render: (text, item) => {
                    return `${item.customer_name}:${item.customer_phone}- (${item.customer_total_orders})`
                }
            },
			{
                title: 'Refund Request',
               render: (text, item) => {
                    return item.refund_request ? <a class="btn btn-danger" href={`${ORDER_PREFIX}/edit/${item.order_id}`}>Pending</a> : '' 
                }
            },
            {
                title: 'Status',
                dataIndex: 'order_status_string'
            },
			{
				title: 'Important',
				render: (text, item) => {
				return <Switch checked={item.is_important}  onChange={(checked) => handleSwitchChange(checked, item.order_id)} />
				}
			},
            {
                title: 'Action', 
                render: (text, record) => (
                    <div className="dropdown dropdown-action text-right">
                        <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            {
                                auth.permissions.includes("OrderDetails") ? <Link className="dropdown-item" to={`${ORDER_PREFIX}/${record.order_id}`}><i className="fa fa-eye m-r-5" /> View detail</Link> : null
                            }
                            {
                                auth.permissions.includes("EditOrder") ? <Link className="dropdown-item" to={`${ORDER_PREFIX}/edit/${record.order_id}`}><i className="fa fa-edit m-r-5" /> Edit Order</Link> : null
                            }
                           {
                                auth.permissions.includes("EditOrder") ? <Link className="dropdown-item" to={`${ORDER_PREFIX}/comments/${record.order_id}`}><i className="fa fa-comment m-r-5" />Comments</Link> : null
                            }
                        </div>
                    </div>
                ),
            },
        ]
        return columns
    }, [auth.permissions, auth.constants])

    const searchChanged = (e) => {
        const value = e.target.value
        console.log(value)
        setParams({...params, offset: 0, searchDate: value})
    }
	const handleSwitchChangeFOrImportant = (checked, index) => {
		setParams({...params, offset: 0, is_important_search: Number(checked)})
		localStorage.setItem("is_important_search", Number(checked))
	}
	const handleSwitchChange = (checked, index) => {
		let formState={ is_important: Number(checked), order_id: index}
		const apiPromise = orderService.setOrderImportant(axiosPrivate, formState)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.setOrderImportant) {
                window.location.reload();
            }
        })		
	};
    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
    const filterChange = (e, name, type) => {
        let newVal = e.target.value
        switch(type) {
            case "num":
                newVal = Number(newVal)
                break
            default:
                break
        }
        setParams({
            ...params,
            offset: 0,
            [name]: newVal
        })
		localStorage.setItem(name, newVal)
    }
    const fleetChanged = (e) => {
        if(e == 0) {
            setParams({...params, searchDeliveryFleetId: null})    
        }else {
			localStorage.setItem("searchDeliveryFleetId", e)
            setParams({...params, searchDeliveryFleetId: e})
        }
    }
	const driverChanged = (e) => {
        if(e == 0) {
            setParams({...params, driver_id: 0})    
        }else {
			localStorage.setItem("driver_id", e)
            setParams({...params, driver_id: e})
        }
    }
	const deliveryChanged = (e) => {
        if(e == 0) {
            setParams({...params, delivery_service: ""})    
        }else {
			localStorage.setItem("delivery_service", e)
            setParams({...params, delivery_service: e})
        }
    }
	const clearAllFilters  = (e) => {
		
		console.log("clearAllFilters")
		localStorage.setItem("searchStatus", -1);
		localStorage.setItem("delivery_service", "");
		localStorage.setItem("searchDate","");
		localStorage.setItem("searchCity","");
		localStorage.setItem("searchDeliveryFleetId",null);
		
		window.location.reload();

		
	}
    const downloadDispatchList = (e) => {
        e.preventDefault()
        if(!params.searchDate) {
            showToast("Please Select Date From Filter", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = orderService.getDispatchList(axiosPrivate, params.searchDate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.ordersEXcelSheet.path) {
                const url = `${API_BASE_URL}${res.data.ordersEXcelSheet.path.replace("./", "")}`
                createLink(url)
            }
        })
    }
	const downloadOrderPorudtcsData = (e) => {
        e.preventDefault()
        if(!params.searchDate) {
            showToast("Please Select Date From Filter", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = orderService.getOrderProductsList(axiosPrivate, params.searchDate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.excelOrdersProductsByDeliveryDate.path) {
                const url = `${API_BASE_URL}${res.data.excelOrdersProductsByDeliveryDate.path.replace("./", "")}`
                createLink(url)
            }
        })
    }
    const downloadVehicleOrders = (e) => {
        e.preventDefault()
        if(!params.searchDate) {
            showToast("Please Select Date From Filter", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!params.searchDeliveryFleetId) {
            showToast("Please Select Fleet From Filter", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = orderService.getOrderLoaderList(axiosPrivate, params.searchDate, params.searchDeliveryFleetId)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.fleetOrdersEXcelSheet.path) {
                const url = `${API_BASE_URL}${res.data.fleetOrdersEXcelSheet.path.replace("./", "")}`
                createLink(url)
            }
        })
    }
	const downloadOrdersCustomers = (e) => {
        e.preventDefault()
        if(!params.searchDate) {
            showToast("Please Select Date From Filter", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = orderService.allOrderCustomersEXcelSheet(axiosPrivate, params.searchDate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.allOrderCustomersEXcelSheet.path) {
                const url = `${API_BASE_URL}${res.data.allOrderCustomersEXcelSheet.path.replace("./", "")}`
                createLink(url)
            }
        })
    }
    const createLink = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Order List</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6 col-6">
                            <h3 className="page-title">Order List</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Order List </li>
								<li className="breadcrumb-item active">Total Order: {orderTotal} </li>
                            </ul>
                        </div>                        
					<div className="col-sm-2 col-2">
						<Link to={"#"} onClick={downloadDispatchList} className="btn btn-primary  float-right d-block mobile-button-first">Dispatch Order List Download</Link>
					  </div>
					<div className="col-sm-2 col-2">
					   <Link to={"#"} onClick={downloadVehicleOrders} className="btn btn-success float-right d-block mobile-button-first" style={{marginRight: "10px"}}>Orders To Load Download</Link>
					 </div>
					 <div className="col-sm-2 col-2">
					   <Link to={"#"} onClick={downloadOrdersCustomers} className="btn btn-warning float-right d-block mobile-button-first" style={{marginRight: "10px"}}>Orders Customers Download</Link>
					 </div>
                    </div>					
                </div>
				<div className="row">
				<div className="col-xl-12 mb-4 col-lg-7 col-12">
                   <div className="card">
                    <div className="card-body">
                      <div className="row gy-3">
                        <div className="col-md-2 col-6">
                          <div className="d-flex align-items-center">
                            <div className="card-info">
                              <h5 className="mb-0">{orderTotal}</h5>
                              <small>Total Orders</small>
                            </div>
                          </div>
                        </div>
						<div className="col-md-2 col-6">
                          <div className="d-flex align-items-center">
                            <div className="card-info">
                              <h5 className="mb-0">${total_amount.toFixed(2)}</h5>
                              <small>Total Amount</small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="d-flex align-items-center">
                            <div className="card-info">
                              <h5 className="mb-0">${total_refund_amount.toFixed(2)}</h5>
                              <small>Refund Amount</small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="d-flex align-items-center">                            
                            <div className="card-info">
                              <h5 className="mb-0">{important_orders}</h5>
                              <small>Important Orders</small>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div className="d-flex align-items-center">                           
                            <div className="card-info">
                              <h5 className="mb-0">{total_refund_request}</h5>
                              <small>Refund Requests</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
				  </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <Collapse>
                            <Panel header="Filters" key="1">
                                <div className='row filter-row'>
                                    <div className='col-md-3 col-6'>
                                        <label className='mobile-lable-spacing'>Search:</label>
                                        <input type="text" placeholder='Search' value={params.searchTerm} className='form-control table-search' onChange={(e) => filterChange(e, "searchTerm", "string")}/>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <label className='mobile-lable-spacing'>Status:</label>
                                        <select value={params.searchStatus} className='form-control table-search' onChange={(e) => filterChange(e, "searchStatus", "num")}>
                                            <option value={-1}>Select Status</option>
                                        {
                                            (auth.constants && auth.constants.hasOwnProperty("ORDER_STS")) ?
                                            Object.keys(auth.constants["ORDER_STS"]).map((key) => {
                                                return (
                                                    <option key={key} value={key}>{auth.constants["ORDER_STS"][key]}</option>
                                                )
                                            }) : null
                                        }
                                        </select>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <label className='mobile-lable-spacing'>City:</label>
                                        <select className='form-control table-search' value={params.searchCity} onChange={(e) => filterChange(e, "searchCity", "string")}>
                                            <option value={""}>Select City</option>
                                            {
                                                citiesData.map((city) => {
                                                    return (
                                                        <option key={city.id} value={city.area_name}>{city.area_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <label className='mobile-lable-spacing'>Delivery Date/Time:</label>
                                        <input type="date" value={params.searchDate} placeholder='Delivery Date/Time' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <label className='mobile-lable-spacing'>Select Fleet</label>
                                        <Select 
                                            showSearch={true}
                                            className='select col-md-12'
                                            options={[{id: 0, fleet_licence_number: "Select Fleet"}, ...fleetData]}
                                            value={params.searchDeliveryFleetId}
                                            onChange={fleetChanged}
                                            optionFilterProp={"fleet_licence_number"}
                                            fieldNames={{label: "fleet_licence_number", value: "id"}}
                                        />
                                    </div>
									<div className='col-md-3 col-6'>
                                        <label className='mobile-lable-spacing'>Select Driver</label>
                                        <Select 
                                            showSearch={true}
                                            className='select col-md-12'
                                            options={[{id: 0, name: "Select Driver"}, ...driver_list]}
                                            value={params.driver_id}
                                            onChange={driverChanged}
                                            optionFilterProp={"name"}
                                            fieldNames={{label: "name", value: "id"}}
                                        />
                                    </div>
									<div className='col-md-3 col-6'>
                                        <label className='mobile-lable-spacing'>Select Delivery</label>
                                        <Select 
                                            className='select col-md-12'
                                            options={DELIVERYTYPE}
                                            value={params.delivery_service}
                                            onChange={deliveryChanged}
                                            optionFilterProp={"label"}
                                            fieldNames={{label: "label", value: "value"}}
                                        />
                                    </div>
									<div className='col-1'>
									<label className='mobile-lable-spacing '>Important</label>
									<Switch checked={params.is_important_search}  onChange={(checked) => handleSwitchChangeFOrImportant(checked, params.is_important_search)} className='col-1'/>
									</div>
									<div className='col-md-3 col-12'>
									<Link to={"#"} onClick={clearAllFilters} className="btn btn-info full-width" style={{marginRight: "10px",marginTop: "10px"}}>Clear Filters</Link>
									</div>
									<div className='col-md-6 col-12'>
									<Link to={"#"} onClick={downloadOrderPorudtcsData} className="btn btn-info" style={{marginRight: "10px",marginTop: "10px"}}>Download products List by Date </Link>
									</div>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive table-order-mobile">
                            <Table
                                pagination= { {total : orderTotal,pageSize: 20, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={orderLoading}               
                                bordered
                                dataSource={orderData}
                                rowKey={record => record.order_id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default OrderList;