import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate,Link } from 'react-router-dom';
import { LICENSE_IMG, TEAM_PREFIX, TOAST_MSG_TYPE, getConstantData,CATEGORY_IMG } from '../../utils/Constants';
import { Select as AntSelect,DatePicker, } from 'antd';
import Select from 'react-select';
import useAuth from '../../hooks/useAuth';
import { useFleetList } from '../../hooks/FleetHooks';
import ImageUpload from '../ImageUpload';
import { teamService } from '../../Services/TeamService';
import countryCodes from '../../utils/countryCodes';
import moment from 'moment';
const initialState = { setting_type:"APP_HOMEPAGE", homepage_text:"",homepage_top_add_color: "", shipping_slot_back_color: "", shipping_slot_back_scroll_color: "", homepage_gif: {server: ""},homepage_image_1: {server: ""}, homepage_colorcode: "",homepage_image_2: {server: ""} }
const TeamForm = (props) => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({paginated: false, limit: 0, offset: 0, search: "", field: "id", sort: "ASC"})
    const {fleetData, loading, error, total} = useFleetList(axiosPrivate, params)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
            teamService.getSettings(axiosPrivate, 'APP_HOMEPAGE').then(res => {
                if(res.data.settingDetailsByType) {
                    const data = res.data.settingDetailsByType.setting_data
					console.log(data,"data")
					setFormState({
					homepage_top_add_color: data.homepage_top_add_color,
					shipping_slot_back_color: data.shipping_slot_back_color,
					shipping_slot_back_scroll_color: data.shipping_slot_back_scroll_color,
					homepage_colorcode: data.homepage_colorcode,
					homepage_text: data.homepage_text,
					homepage_gif_old: data.homepage_gif,
					homepage_image_1_old: data.homepage_image_1,
					homepage_image_2_old: data.homepage_image_2,
					homepage_gif: {server: data.homepage_gif ? data.homepage_gif : ""},
					homepage_image_1: {server: data.homepage_image_1 ? data.homepage_image_1 : ""},
					homepage_image_2: {server: data.homepage_image_2 ? data.homepage_image_2: ""}
					})
                    setShowLoader(false)
                }else{
                    navigate(`${TEAM_PREFIX}`)
                }
            })
    }, [props.member_id])
	
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }    
    const onChangeDate = (value, dateString) => {
        setFormState({...formState, attdate: dateString})
    }
	const onChangeInTime = (value, dateString) => {
        setFormState({...formState, att_in_time: dateString})
    }
	const onChangeOutTime = (value, dateString) => {
        setFormState({...formState, att_out_time: dateString})
    }
    const submitHandler = (e) => {
        e.preventDefault()
        let param = {
			setting_type: "APP_HOMEPAGE",
			homepage_top_add_color: formState.homepage_top_add_color,
			shipping_slot_back_color: formState.shipping_slot_back_color,
			shipping_slot_back_scroll_color: formState.shipping_slot_back_scroll_color,
			homepage_colorcode: formState.homepage_colorcode,
			homepage_text: formState.homepage_text,
			homepage_gif_old: formState.homepage_gif_old,
			homepage_image_1_old: formState.homepage_image_1_old,
			homepage_image_2_old: formState.homepage_image_2_old,
        }
		console.log(param)        
        const apiPromise = teamService.updateSettings(axiosPrivate, param,formState.homepage_gif.server,formState.homepage_image_1.server,formState.homepage_image_2.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.updateSettings) {
                showToast(props.crud === "create" ? "Setting Updated Successfully!" : "Setting Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                //setFormState({...initialState})
               // navigate(`/dashboard`)
            }
        })
    }
    
    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
			    
                <div className='row mb-4'>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>App HomePage Heading Title *</label>
                            <input type="text" className='form-control' name='homepage_text' value={formState.homepage_text} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>App HomePage Title Color Code *</label>
                            <input type="text" className='form-control' name='homepage_colorcode' value={formState.homepage_colorcode} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>App HomePage Top Address Color Code *</label>
                            <input type="text" className='form-control' name='homepage_top_add_color' value={formState.homepage_top_add_color} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>App HomePage Shipping Slot Color Code *</label>
                            <input type="text" className='form-control' name='shipping_slot_back_color' value={formState.shipping_slot_back_color} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-5'>
                        <div className='form-group'>
                            <label>App HomePage Shipping Slot Color Code after scroll *</label>
                            <input type="text" className='form-control' name='shipping_slot_back_scroll_color' value={formState.shipping_slot_back_scroll_color} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                                <label>Upload HomePage Gif</label>
                                <ImageUpload
                                    fileName={formState.homepage_gif.server}
                                    fileList={
                                    formState.homepage_gif.server
                                        ? [
                                            {
                                            uid: formState.homepage_gif,
                                            url:"local" in formState.homepage_gif ? `${formState.homepage_gif.local.url}` : `${formState.homepage_gif.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setFormState({...formState, homepage_gif: file});
                                    }}
                                    onRemove={() => setFormState({...formState, homepage_gif: {server: ""},homepage_gif_old:""})}
                                    crud="create"
                                />
						</div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                                <label>Upload HomePage Top Image 1</label>
                                <ImageUpload
                                    fileName={formState.homepage_image_1.server}
                                    fileList={
                                    formState.homepage_image_1.server
                                        ? [
                                            {
                                            uid: formState.homepage_image_1,
                                            url:"local" in formState.homepage_image_1 ? `${formState.homepage_image_1.local.url}` : `${formState.homepage_image_1.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setFormState({...formState, homepage_image_1: file});
                                    }}
                                    onRemove={() => setFormState({...formState, homepage_image_1: {server: ""},homepage_image_1_old:""})}
                                    crud="create"
                                />
						</div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                                <label>Upload HomePage Top Image 2</label>
                                <ImageUpload
                                    fileName={formState.homepage_image_2.server}
                                    fileList={
                                    formState.homepage_image_2.server
                                        ? [
                                            {
                                            uid: formState.homepage_image_2,
                                            url:"local" in formState.homepage_image_2 ? `${formState.homepage_image_2.local.url}` : `${formState.homepage_image_2.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setFormState({...formState, homepage_image_2: file});
                                    }}
                                    onRemove={() => setFormState({...formState, homepage_image_2: {server: ""},homepage_image_2_old:""})}
                                    crud="create"
                                />
						</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default TeamForm