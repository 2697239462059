import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, BANNER_IMG, GIFT_PREFIX, COUPON_TYPE, TOAST_MSG_TYPE,COUPON_AMOUNT_TYPE,NOTICE_PREFIX } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';
import { productService } from '../../Services/ProductService';
import { Select } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import ImageUpload from '../ImageUpload';
import { BANNER_LINK_TYPE } from '../../utils/Constants';
import { useCostomerAppTokenList } from '../../hooks/ProductHooks';
import { useNotificationsList,useCityList,useCustomerTableCityList } from '../../hooks/RouteHooks';
//import { useMerchantProductList } from '../../hooks/ProductHooks';
import { useAdminCateogryList } from '../../hooks/CategoryHooks';
const dateFormat = 'YYYY/MM/DD';
const REWARD_TYPE = [
	{label: "Credit", value: "CREDIT"},
	{label: "Debit", value: "DEBIT"}
]
const CARD_TYPE = [
	{label: "All Customers", value: "ALL_USERS"},
	{label: "Select Customers", value: "SELECTED_CUSTOMERS"},
    {label: "Cart Customers", value: "CART_USERS"},
    {label: "New Customers", value: "NEW_USERS"},	
	{label: "Order Customers", value: "ORDER_CUSTOMERS"},
	{label: "Default Merchant Customers", value: "DEFAULT_MERCHANT_CUSTOMERS"},
	{label: "Non-Default Merchant Customers", value: "NON_DEFAULT_MERCHANT_CUSTOMERS"}
]
const initialState = {message: "", title:"",send_to:[],customer_city:"",firebase_topic_name:"",total_reward_points:0,amount_type:""}
const GiftCardForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
	const [sendNotification,setSendNotification]= useState(false)
	
	const [params, setParams] = useState({paginated: false, limit: 20, offset: 0, search: "", field: "id", sort: "DESC", counter: 0,customer_city: "",fetch_now:false })
	const {categoryData} = useAdminCateogryList(axiosPrivate, params)
	const [cityParam, seCityParams] = useState({paginated: false, offset:0, limit: 10, field: "area_name", sort: "ASC", search: ""})
    const {citiesData, loading:Floading, error:Eerror} = useCustomerTableCityList(axiosPrivate);
	
	const [optionsProducts, setOptionsProducts] = useState([]);
	const [customerData, setCustomerData] = useState([]);
    useEffect(() => {
        setShowLoader(false)
    }, [props.id])
    
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])
    const onClickProdcut= () => {
		if(customerData && customerData.length==0){
		setParams({
            ...params,
			fetch_now: true
        })
		}
	}
    const onChangeField = (e) => {		
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    const onChangeProduct = (e) => {
		console.log(formState)
		setFormState({...formState, send_to: e})		
    }
	const onChangeRewardType = (value) => {		
	   setFormState({...formState, amount_type: value});
	}
	const onChangeBannerType = (value) => {		
		setFormState({...formState, firebase_topic_name: value});
    }
	const filterChange = (e) => {
		console.log(e)
        let newVal = e
		setFormState({...formState, customer_city: newVal,firebase_topic_name:""})
    }
	const handleInputChangeCustomers = (inputValue) => {
		let paramData= { search_string:inputValue, customer_city:params.customer_city  }
			if (inputValue && inputValue.length>2 && formState.firebase_topic_name!=="ORDER_CUSTOMERS") {
			productService.pushNotificationCustomers(axiosPrivate, paramData )
			  .then((res) => {
				const relatedProductList = res.data.pushNotificationCustomers;
				setCustomerData(relatedProductList);
				return;
			  })
			  .catch(error => {
				console.error('Error searching for related products:', error);
			  });
			}
	}
	const checkboxChanged = (e) => {
		console.log( e.target)
		var name = e.target.name
		if(name==='sendNotification'){		
		setSendNotification(!sendNotification)
		}
		setFormState({...formState, title: "",message:""})
    }
    const submitHandler = (e) => {
        e.preventDefault()
		let paramData ={
			title: formState.title,
			message: formState.message,
			firebase_topic_name: formState.firebase_topic_name,
			customer_city: formState.customer_city ? formState.customer_city : "",
			send_to: formState.send_to,
			total_reward_points: parseFloat(formState.total_reward_points),
			amount_type: formState.amount_type
		}
        const apiPromise = productService.updateCustomerRewards(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.updateCustomerGroupRewardPoints) {
                showToast(props.crud === "create" ? "Points Updated Successfully!" : "Points Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
            }
        })
    }
    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                
				<div className='row mb-4'>
				
				<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Customer Filter*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.firebase_topic_name}
                                options={CARD_TYPE}
                                onChange={onChangeBannerType}
                            />
                        </div>
                    </div> 	
				<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Select Type*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.amount_type}
                                options={REWARD_TYPE}
                                onChange={onChangeRewardType}
                            />
                        </div>
                    </div> 
				<div className='col-md-3'>
					<div className='form-group'>
						<label>Total Reward Points</label>
						<input type="number" className='form-control' name='total_reward_points' value={formState.total_reward_points} onChange={onChangeField} />
					</div>
					</div>	
                    {
                    formState.firebase_topic_name=="SELECTED_CUSTOMERS" || formState.firebase_topic_name=="ORDER_CUSTOMERS" ?  
					<div className='col-md-12'>
						<div className='form-group'>
							<label>Select Customer</label>
							<Select 
								showSearch={true}
								mode="multiple"
								className='select col-md-12'
								value={formState.send_to}
								options={customerData}
								onChange={onChangeProduct}
								onClick={onClickProdcut}
								optionFilterProp={"email"}
								fieldNames={{label: "email", value: "id"}}
								onSearch={handleInputChangeCustomers}
							/>
						</div>
					</div>: null   
					}					
				</div>										
				<div className='row mb-4'>
					<div className="col-md-4">
							<input type="checkbox"  style={{marginRight: "10px"}} checked={sendNotification} onChange={checkboxChanged} name="sendNotification"/>
							<label><b>Send Notification To Customer ?</b></label>
					</div>
				</div>
				<div className='row mb-4'>
					{
                    sendNotification ?  
					<div className='col-md-12'>
					<div className='form-group'>
						<label>Notification Title</label>
						<input type="text" className='form-control' name='title' value={formState.title} onChange={onChangeField} />
					</div>
					</div>
					: null 
					}
					{
                    sendNotification ? 
					<div className='col-md-12'>
						<div className='form-group'>
							<label>Notification Message:</label>
							<textarea className='form-control' rows={4} value={formState.message} name='message' onChange={onChangeField}></textarea>
						</div>
					</div>
					: null 
					}
				</div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Submit</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default GiftCardForm