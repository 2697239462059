class OrderService {
	downloadOrderInvoice(axios, params) {
        let body = {
            query: `
                query {
                    generateOrderInvoice(order_id: "${params.order_id}")
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	customerRewardPointsList(axios, params) {
        let query = `
                query($sortBy: String, $sortOrder: String, $paginated: Boolean, $limit: Int, $offset: Int, $searchTerm: String, $customer_id: Int!) {
                    customerRewardPointsList(
                        sortBy: $sortBy
                        sortOrder: $sortOrder
                        paginated: $paginated
                        limit: $limit
                        offset: $offset
                        searchTerm: $searchTerm
                        customer_id: $customer_id
                    ) {
                        list {
                            order_id
                            id
                            createdAt
                            reward_points
                        }
                        total
                    }
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params,
					customer_id: params.customer_id
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	orderCommentsListing(axios, params) {
        let query = `
                query($sortBy: String, $sortOrder: String, $paginated: Boolean, $limit: Int, $offset: Int, $searchTerm: String, $order_id: String!) {
                    orderCommentsListing(
                        sortBy: $sortBy
                        sortOrder: $sortOrder
                        paginated: $paginated
                        limit: $limit
                        offset: $offset
                        searchTerm: $searchTerm
                        order_id: $order_id
                    ) {
                        list {
                            event_new_data
                            id
                            createdAt
                            event_triggered_by
                        }
                        total
                    }
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params,
					order_id: params.order_id
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getOrdersList(axios, params) {
        let query = `
                query($delivery_service:String,$driver_id:Int,$sortBy: String, $sortOrder: String, $paginated: Boolean, $limit: Int, $offset: Int, $searchTerm: String!, $searchDate: String, $searchCity: String, $searchStatus: Int, $searchDeliveryFleetId: Int, $is_important_search:Int) {
                    orderListingCMS(
                        sortBy: $sortBy
                        sortOrder: $sortOrder
                        paginated: $paginated
                        limit: $limit
                        offset: $offset
                        searchTerm: $searchTerm
                        searchDate: $searchDate
                        searchCity: $searchCity
                        searchStatus: $searchStatus
                        searchDeliveryFleetId: $searchDeliveryFleetId
						driver_id: $driver_id
						delivery_service: $delivery_service
						is_important_search: $is_important_search
                    ) {
                        list {
                            order_id
                            store_id
                            payment_id
                            price_total
                            sub_total
                            coupon_discount
                            driver_tip
                            shipping_charges
                            total_paid_amount
                            shippping_slot
                            order_status
                            order_status_string
                            shipping_address
                            coupon_data
                            order_business_type
                            createdAt
                            updatedAt
                            total_items
                            customer_id
                            fleet_licence_number
							customer_phone
	                        customer_name
							refund_request
							customer_total_orders
							is_important
							delivery_service
                        }
						total
						total_amount
						total_refund_amount
						total_refund_request
						important_orders
						driver_list
                    }
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getOrdersProductsList(axios, params) {
        let query = `
                query($productCategory:Int,$sortBy: String, $sortOrder: String, $paginated: Boolean, $limit: Int, $offset: Int, $searchTerm: String!, $searchDate: String) {
                    excelOrdersProductsByDeliveryDateList(
                        sortBy: $sortBy
                        sortOrder: $sortOrder
                        paginated: $paginated
                        limit: $limit
                        offset: $offset
                        searchTerm: $searchTerm
                        searchDate: $searchDate
						productCategory: $productCategory
                    ) {
                        list {
							product_id
							total_price
							total_qty
							product_price
							supplier_price
							product_title
							product_brand					
							product_variant
							pflag
							product_image
                        }
                        total
                    }
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getOrderDetails(axios, orderID) {
        let body = {
            query: `
                query {
                    orderDetailsCMS(
                        order_id: "${orderID}"
                    ) {
                        order_details {
							customer_id
                            order_id
                            store_id
                            payment_id
                            price_total
                            sub_total
                            coupon_discount
                            driver_tip
                            shipping_charges
                            total_paid_amount
                            shippping_slot
                            order_status
                            order_status_string
                            shipping_address
                            coupon_data
                            order_business_type
                            createdAt
                            total_items
                            transaction_date_time
                            box_count
                            fleet_slot_id
							products_hst_charge
							extra_amount_payment
							paid_on_ordertime
							refund_amount
							reward_points_used
							delivery_image
							delivery_image1
							delivery_image2
							delivery_service
							customer_fixed_note
							packing_handing_charges
                        }
                        order_items {
                            id
                            order_id
                            product_id
                            variant_id
                            product_price
                            quantity
                            total_price
                            product_details
                            variant_details
                            product_rackid
							packing_status
							out_stock_qty
							product_hst_charge
							item_other_data
                        }
                        driver_fleet_details
						driver_list
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	setOrderImportant(axios, params) {
        let query = `
                mutation($order_id: String!, $is_important: Int) {
                    setOrderImportant(
                        order_id: $order_id
                        is_important: $is_important
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	sendCustomerFeedBackEmail(axios, order_id) {
        let query = `
                mutation($order_id: String!) {
                    sendCustomerFeedBackEmail(order_id: $order_id)
                }
            `
        const operations = 
            {
                query, 
                variables: { order_id: order_id   }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	setOrderProductFlag(axios, params) {
        let query = `
                mutation($product_id: Int!, $pflag: Int,$delivery_date:String) {
                    setOrderProductFlag(
                        product_id: $product_id
                        delivery_date: $delivery_date
						pflag: $pflag
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    updateOrderStatus(axios, params, deliveryImage, deliveryImage1, deliveryImage2) {
        let query = `
                mutation($driver_id:Int,$order_id: String!, $order_status: Int!, $note: String, $box_count: Int, $fleet_slot_id: String,$send_notification: Boolean,$send_email: Boolean,$delivery_image: Upload, $delivery_image1: Upload, $delivery_image2: Upload) {
                    changeOrderStatus(
                        order_id: $order_id
                        order_status: $order_status
                        note: $note
                        box_count: $box_count
                        fleet_slot_id: $fleet_slot_id
						send_notification: $send_notification
						send_email: $send_email
						delivery_image: $delivery_image
						delivery_image1: $delivery_image1
						delivery_image2: $delivery_image2
						driver_id: $driver_id
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params,
					delivery_image: null
                }
            }
       if(typeof deliveryImage === "object" || typeof deliveryImage1 === "object" || typeof deliveryImage2 === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": [],
                "1": [],
				"2": []
            }
			
            if(typeof deliveryImage === "object") {
                map["0"].push("variables.delivery_image")
            }else if(typeof deliveryImage === "string" && deliveryImage !== ""){
                operations.variables.delivery_image = deliveryImage
            }
			if(typeof deliveryImage1 === "object") {
                map["1"].push("variables.delivery_image1")
            }else if(typeof deliveryImage1 === "string" && deliveryImage1 !== ""){
                operations.variables.delivery_image1 = deliveryImage1
            }
			if(typeof deliveryImage2 === "object") {
                map["2"].push("variables.delivery_image2")
            }else if(typeof deliveryImage2 === "string" && deliveryImage2 !== ""){
                operations.variables.delivery_image2 = deliveryImage2
            }
			
			formData.append("map", JSON.stringify(map))
			
            if(typeof deliveryImage === "object") {
                formData.append("0", deliveryImage)
            }
            if(typeof deliveryImage1 === "object") {
                formData.append("1", deliveryImage1)
            }
			if(typeof deliveryImage2 === "object") {
                formData.append("2", deliveryImage2)
            }
            
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
			
        }else {
            if(typeof deliveryImage === "string" && deliveryImage !== "") {
                operations.variables.delivery_image = deliveryImage
            }
            return axios.post('', JSON.stringify(operations)).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }
	manageCustomerRewardPoints(axios, params) {
        let query = `
                mutation($customer_id: Int!, $reward_points: Float) {
                    manageCustomerRewardPoints(
                        customer_id: $customer_id
                        reward_points: $reward_points
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	deleteCustomerFixedNote(axios, params){
		let query =`
		mutation($customer_id:Int!,$note: String){
			addUpdateCustomerFixedNote(customer_id: $customer_id,note:$note)
		}
		`
		const operations={
			query,
			variables : {
				...params
			}
		}
		return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
	}
	deleteOrderComment(axios, params){
		let query =`
		mutation($id:Int!){
			deleteOrderComment(id: $id)
		}
		`
		const operations={
			query,
			variables : {
				...params
			}
		}
		return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
	}
	addOrderComment(axios, params) {
        let query = `
                mutation($order_id: String!, $comment_text: String!,$send_notification:Boolean,$customer_fixed_note:Boolean) {
                    addOrderComment(
                        order_id: $order_id
                        comment_text: $comment_text
						send_notification: $send_notification
						customer_fixed_note: $customer_fixed_note
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    editCustomerOrder(axios, params) {
        let query = `
                mutation($order_id: String!, $orderItems: [edit_order_item], $shippping_slot: JSON,$custom_refund_amount: Float,$send_email: Boolean) {
                    editCustomerOrder(
                        order_id: $order_id
                        orderItems: $orderItems
                        shippping_slot: $shippping_slot
						custom_refund_amount: $custom_refund_amount
						send_email: $send_email
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getOrderStatusLog(axios, orderID) {
        let body = {
            query: `
                query {
                    orderStatusLogs(
                        order_id: "${orderID}"
                    ) {
                        id
                        event_id
                        event_type
                        event_prev_data
                        event_new_data
                        event_triggered_by
                        createdAt
                        updatedAt
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getDispatchList(axios, date) {
        let body = {
            query: `
                query {
                    ordersEXcelSheet(
                        order_date: "${date}"
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getOrderProductsList(axios, date) {
        let body = {
            query: `
                query {
                    excelOrdersProductsByDeliveryDate(
                        searchDate: "${date}"
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getOrderLoaderList(axios, date, fleetID) {
        let body = {
            query: `
                query {
                    fleetOrdersEXcelSheet(
                        order_delivery_date: "${date}"
                        delivery_fleet_id: ${fleetID}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	allOrderCustomersEXcelSheet(axios, date) {
        let body = {
            query: `
                query {
                    allOrderCustomersEXcelSheet(
                        order_delivery_date: "${date}"
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

const orderService = new OrderService()
export {
    orderService
}