import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, DAYS, ROUTE_PREFIX, TIMING, TOAST_MSG_TYPE } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';
import { Select } from 'antd';
import RouteCity from './RouteCity';
import { useCityList } from '../../hooks/RouteHooks';
import { useFleetList } from '../../hooks/FleetHooks';

const initialState = { area_counter: 0, route_id: 0, route_name: "", order_end_time:"01:00", order_limit: 0, area_data: [], days_name: [], route_fleets: [],notification_flag: true }
const RouteForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [fleetParams, setParams] = useState({paginated: false, limit: 0, offset: 0, search: "", field: "id", sort: "ASC"})
    const {fleetData, loading:fleetLoading} = useFleetList(axiosPrivate, fleetParams)
    const [queryParam, setQueryParam] = useState({paginated: false, limit: 10, offset: 0, search: "", field: "area_name", sort: "ASC"})
    const {citiesData, loading, error, total} = useCityList(axiosPrivate, queryParam)  
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        if(props.route_id && citiesData && citiesData.length) {
            routeService.routeByID(axiosPrivate, props.route_id).then(res => {
                if(res.data.routeDetailsById) {
                    const data = res.data.routeDetailsById
                    console.log(data)
                    let areaData = []
                    let counter = 0
                    for(let i=0;i<data.area_data.length;i++) {
						console.log(data.area_data[i])
                        let localID = `local_${counter}`
                        let areaObj = {
                            area: citiesData.find((city) => city.id == data.area_data[i].area),
                            enter_time: {label: data.area_data[i].enter_time, value: data.area_data[i].enter_time},
                            exit_time: {label: data.area_data[i].exit_time, value: data.area_data[i].exit_time},
							shipping_charges: data.area_data[i].shipping_charges,
							shipping_charges_2: data.area_data[i].shipping_charges_2,
                            localID: localID
                        }
                        areaData.push(areaObj)
                        counter = counter + 1
                    }
                    
                    setFormState({
                        ...formState,
                        route_id: data.id,
                        route_name: data.route_name,
                        area_data: [...areaData],
                        days_name: data.days_name,
                        order_limit: data.order_limit,
                        area_counter: counter,
                        route_fleets: data.route_fleets,
                        order_end_time: data.order_end_time,
						notification_flag: data.notification_flag
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${ROUTE_PREFIX}`)
                }
            })  
        }else {
            setShowLoader(false)
        }
    }, [props.route_id, citiesData])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
const checkboxChanged = (e) => {
        var name = e.target.name
        var newValue = !formState[name]
        setFormState({...formState, [name]: newValue})
		console.log(formState)
    }
    const onChangeEndTime = (e) => {
        console.log(e)
        setFormState({...formState, order_end_time: e})
    }

    const onChangeFleet = (value) => {
        let fleets = fleetData.filter((fleet) => {
            return value.includes(fleet.id)
        })
        let sum = fleets.reduce((prev, obj) => {
            return prev+obj.fleet_order_limit
        }, 0)
        setFormState({...formState, route_fleets: [...value], order_limit: sum})
    }
    const onChangeDays = (e) => {
        setFormState({...formState, days_name: [...e]})
    }
    
    const updateFormState = (updatedObj, localID) => {
        let index = formState.area_data.findIndex((area) => area.localID === localID)
        if(index !== -1) {
            formState.area_data[index] = {...updatedObj}
        }
    }

    const addNewCity = (e) => {
        e.preventDefault()
        let newAreaObj = {
            area: undefined,
            enter_time: {label: "00:00", value: "00:00"},
            exit_time: {label: "00:30", value: "00:30"},
            localID: "local_"+formState.area_counter
        }
        let newCounter = formState.area_counter + 1
        setFormState({...formState, area_data: [...formState.area_data, {...newAreaObj}], area_counter: newCounter})
    }

    const deleteCity = (e, localID) => {
        e.preventDefault()
        let newAreaArray = formState.area_data.filter((area) => area.localID !== localID)
        setFormState({...formState, area_data: [...newAreaArray]})
    }

    const timeCheckerAndDataCreation = (formData) => {
        for(var i=0;i<formState.area_data.length;i++) {
            let area = formState.area_data[i]
            let enterTime = area.enter_time.value.split(":")
            let exitTime = area.exit_time.value.split(":")
            if(!area.area) {
                showToast(`Please Select Area`, TOAST_MSG_TYPE.ERROR)
                return false
            }
            if(enterTime[0] > exitTime[0]) {
                showToast(`Please fill correct timing for ${area.area.area_name}`, TOAST_MSG_TYPE.ERROR)
                return false
            }else if(enterTime[0] == exitTime[0] && enterTime[1] == exitTime[1]) {
                showToast(`Please fill correct timing for ${area.area.area_name}`, TOAST_MSG_TYPE.ERROR)
                return false
            }
            formData.area_data.push({
                area: area.area.id,
                enter_time: area.enter_time.value,
                exit_time: area.exit_time.value,
				shipping_charges: area.shipping_charges,
				shipping_charges_2: area.shipping_charges_2
            })
        }
        return true
    }
    const submitHandler = (e) => {
        e.preventDefault()
        let formData = {
            route_id: formState.route_id,
            route_name: formState.route_name,
            order_limit: formState.order_limit,
            route_fleets: formState.route_fleets,
            days_name: formState.days_name,
            order_end_time: formState.order_end_time,
            area_data: [],
			notification_flag: formState.notification_flag
        }
        if(!formData.route_name) {
            showToast("Please Fill Route Name", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formData.order_limit) {
            showToast("Please Fill Order Limit", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formData.days_name.length) {
            showToast("Please Select Days", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!timeCheckerAndDataCreation(formData)) {
            return
        }
        const apiPromise = routeService.addUpdateRoute(axiosPrivate, formData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.createUpdateRoute) {
                showToast(props.crud === "create" ? "Route Added Successfully!" : "Route Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${ROUTE_PREFIX}`)
            }
        })
    }
    return (
        <>
        {
            showLoader || loading ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-9'>
                        <div className='form-group'>
                            <label>Route Name*</label>
                            <input type="text" className='form-control' name='route_name' value={formState.route_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Last Order Time<span className="text-danger">*</span></label>
                            <Select 
                                className="select col-md-12" 
                                value={formState.order_end_time} 
                                options={TIMING.map(t => ({value: t, label: t}))}
                                name="order_end_time" 
                                onChange={(e) => onChangeEndTime(e)} 
                                isDisabled={disabled}
                            >
                            </Select>
                        </div>
                    </div>
					<div className="col-md-4">
						<input type="checkbox" id="notification_flag" style={{marginRight: "10px"}} name="notification_flag" checked={formState.notification_flag} onChange={checkboxChanged}/>
						<label htmlFor="notification_flag">Allow Notifications ?</label>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label>Route Fleets*</label>
                            <Select 
                                showSearch={true}
                                mode='multiple'
                                className='select col-md-12'
                                value={formState.route_fleets}
                                options={fleetData}
                                onChange={onChangeFleet}
                                optionFilterProp={"fleet_licence_number"}
                                fieldNames={{label: "fleet_licence_number", value: "id"}}
                            />
                        </div>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Order Limit*</label>
                            <input type="text" className='form-control' name='order_limit' value={formState.order_limit} readOnly={true} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <label>Route Days<span className="text-danger">*</span></label>
                        <Select 
                            showSearch={true}
                            mode='multiple'
                            className='select col-md-12'
                            value={formState.days_name}
                            options={DAYS}
                            isDisabled={disabled}
                            onChange={onChangeDays}
                            optionFilterProp={"value"}
                            fieldNames={{label: "label", value: "value"}}
                        />
                    </div>
                </div>
                {
                    formState.area_data.map((area, index) => {
                        return <RouteCity updateFormState={updateFormState} deleteCity={deleteCity} key={index} area={area} loading={loading} citiesData={citiesData} />
                    })
                }
                <div className='row position-relative'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary float-right" onClick={addNewCity}>Add New City</button>
                    </div>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto mt-3 d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default RouteForm