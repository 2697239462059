import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useNavigate } from 'react-router-dom';
import { COUPON_PREFIX, MERCHANT_PREFIX, TOAST_MSG_TYPE, getConstantData,PUSHNOTICE_PREFIX,API_BASE_URL,NOTICE_PREFIX } from '../utils/Constants';
import { Table,Tooltip  } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { userService } from '../Services/UserService';
import { useAdminRewardList,useCityList } from '../hooks/RouteHooks';
import { routeService } from '../Services/RouteService';
import { productService } from '../Services/ProductService';

const GiftCards = () => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 20, offset: 0, search: "", field: "id", sort: "DESC"})
    const {giftCardData, loading, error, total} = useAdminRewardList(axiosPrivate, params)
    
    const render = useMemo(() => {
        const columns = [            
			{
                title: 'Type',
                dataIndex: 'transaction_type',
				width: 150 // Set your desired width in pixels
            },
			{
                title: 'Reward Points',
                dataIndex: 'reward_points',
				render: (text,row) => {
					return row.reward_points;
				}
            },
		{
		title: 'send_to',
		dataIndex: 'send_to',
		width: 150,
		render: (text) => (
		<Tooltip title={text}>
		<div style={{ width: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
			{text}
		</div>
		</Tooltip>
		)
		},
			{
                title: 'Notification title',
                dataIndex: 'title'
            },
			{
                title: 'Notification Message',
                dataIndex: 'message'
            },			
			{
                title: 'CreatedAt',
                dataIndex: 'createdAt',
				render: (text,row) => {
                    return moment.unix(row.createdAt/1000).format("YYYY-MM-DD: HH:mm:ss")
                }
            },
        ]
        return columns
    }, [auth.permissions])

    const getCityStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }
	const changeDeletePushNotifcation = (e, record) => {
		const resdelete = window.confirm(`Do You want to Remove Notication '${record.notification_title}'`);
		if(resdelete){
			let paramData ={
			id: record.id
			}
			const apiPromise = productService.deleteNotification(axiosPrivate, paramData)
			showPromiseToast(apiPromise, "Please Wait...")
			apiPromise.then(res => {
				if(res.data.deletePushNotification) {
					showToast("Notification delete Successfully!", TOAST_MSG_TYPE.SUCCESS)
					window.location.reload();
				}
			})
		}
	}

    const changeCityStatus = (e, record) => {
		const resdelete = window.confirm(`Do You want to Repeat Notication '${record.notification_title}'`);
		if(resdelete){
        let paramData ={
			title: record.notification_title,
			message: record.notification_body,
			external_link: record.notification_data.external_link,
			notification_type: "GENERAL",
			firebase_topic_name: record.notification_type,
			customer_city: record.notification_data.customer_city,
			send_to: record.send_to,
			product_id: record.notification_data && record.notification_data.product_id ? record.notification_data.product_id: 0,
			category_id: record.notification_data && record.notification_data.category_id ? record.notification_data.category_id: 0
		}
        const apiPromise = productService.sendNotifications(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.sendNotifications) {
                showToast("Notification Send Successfully!", TOAST_MSG_TYPE.SUCCESS)
                navigate(`${NOTICE_PREFIX}`)
            }
        })
		}
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Gift Cards</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Customer Group Rewards</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Rewards Logs Admin</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddCity") ?
                            <div className="col-sm-6">
                                <Link to={`/update-customer-group-rewards`} className="btn btn-primary btn-width float-right d-block">Credit/Debit New Points</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
							
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
    pagination={{ total: total, pageSize: 20, showSizeChanger: false, itemRender: itemRender }}
    style={{ overflowX: 'auto' }}
    columns={render}
    loading={loading}               
    bordered
    dataSource={giftCardData}
    rowKey={record => record.id}
    onChange={handleTableChange}
    scroll={{ x: "max-content" }} // Enable horizontal scroll
/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default GiftCards;