import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { LICENSE_IMG, TEAM_PREFIX, TOAST_MSG_TYPE, getConstantData } from '../../utils/Constants';
import { Select as AntSelect,DatePicker,Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select';
import useAuth from '../../hooks/useAuth';
import { useFleetList } from '../../hooks/FleetHooks';
import ImageUpload from '../ImageUpload';
import { teamService } from '../../Services/TeamService';
import countryCodes from '../../utils/countryCodes';
import moment from 'moment';
const initialState = {id: 0, expense_type: "", user_id: 0, expense_total_amount: "", expense_date: undefined,expense_note: "",expense_images:[] }
const TeamForm = (props) => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({paginated: false, limit: 0, offset: 0, search: "", field: "id", sort: "ASC"})
    const {fleetData, loading, error, total} = useFleetList(axiosPrivate, params)
    const [formState, setFormState] = useState({...initialState,user_id:props.user_id})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
	const [optionsBrand, setOptionsBrand] = useState([]);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if(props.member_id) {
            teamService.expenseDetailsByID(axiosPrivate, props.member_id).then(res => {
                if(res.data.expenseDetailsByID) {
                    const data = res.data.expenseDetailsByID
                    setFormState({
                        ...data,
						id: props.member_id,
                        expense_type: data.expense_type ? data.expense_type : "",
                        expense_total_amount: data.expense_total_amount,
						expense_date: data.expense_date ? moment(data.expense_date) : undefined,
						expense_note: data.expense_note,
						user_id: props.user_id
                    })
					/*if(data.expense_images && (data.expense_images).length>0){
						setFileList(formState.product_images);
					}*/
                    setShowLoader(false)
                }else {
                    navigate(`${TEAM_PREFIX}`)
                }
            })
        }else {
            setShowLoader(false)
        }
    }, [props.member_id])
	
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])
	
	const handleChange = ({ file, fileList: newFileList }) => {
    if (file.status === 'removed') {
    const removedFileName = file.name;
    const updatedFileList = newFileList.filter(item => item.name !== removedFileName);
    setFileList(updatedFileList);
	setFormState({...formState,'expense_images': updatedFileList})
    } else {
      setFileList(newFileList);
	  setFormState({...formState,'expense_images': newFileList})
      if (file.status === 'done') {
        const uploadedFile = file.originFileObj;
        console.log('Uploaded file:', uploadedFile);
      } else if (file.status === 'error') {
        console.log('Error uploading file:', file);
      }
    }
  };
	const handleInputChangeBrand = (inputValue) => {
	setMenuIsOpen(!!inputValue);
	if (inputValue) {
		teamService.searchExpenseTypes(axiosPrivate, inputValue)
			.then((res) => {
				const brandList = res.expenseTypes;
				const newOptions = brandList?.map(brand => ({
					value: brand.expense_name,
					label: brand.expense_name
				}));
				// Filter out duplicates and merge with existing options
				const uniqueOptions = Array.from(new Set([...optionsBrand, ...newOptions]));
				setOptionsBrand(uniqueOptions);
			})
			.catch(error => {
				console.error('Error searching for brands:', error);
		 });
	}
	};
	const handleCreateBrand = (inputValue) => {
    // Check if the option already exists
		const optionExists = optionsBrand.some(option => option.label.toLowerCase() === inputValue.toLowerCase());
		if (!optionExists) {
		  const newOption = { value: inputValue, label: inputValue };
		  setOptionsBrand([...optionsBrand, newOption]);
		  setFormState({...formState, 'expense_type': inputValue})
		}
    };
    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }    
    const onChangeDate = (value, dateString) => {
		console.log("dateString",dateString)
        setFormState({...formState, expense_date: moment(dateString)})
    }
	const onChangeInTime = (value, dateString) => {
        setFormState({...formState, expense_type: dateString})
    }
	const onChangeOutTime = (value, dateString) => {
        setFormState({...formState, user_id: dateString})
    }

    const validateForm = () => {
		console.log(formState)
        if(!formState.expense_type) {
            showToast("Please Choose In Time and date", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(!formState.user_id) {
            showToast("Please Out Time and date", TOAST_MSG_TYPE.ERROR)
            return false
        }
        return true
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(!validateForm()) {
            return
        }
        let param = {
            id: parseInt(formState.id),
            expense_date: moment(formState.expense_date).format("yy-MM-DD"),
            expense_type: formState.expense_type,
            expense_total_amount: parseFloat(formState.expense_total_amount),
			expense_note: formState.expense_note,
			user_id: parseInt(formState.user_id)
        }
		console.log(param)
        
        const apiPromise = teamService.addUpdateExpense(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.addUpdateStaffExpense) {
                showToast(props.crud === "create" ? "Updated Successfully!" : "Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`/expenses/${props.user_id}/${props.year}/${props.month}`)
            }
        })
    }    
    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Date *</label>							
							<DatePicker 
                                placeholder="Select Start Date" 
                                className="form-control" 
                                onChange={onChangeDate} 
                                value={formState.expense_date || ""}
                            />
						</div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Expense Type *</label>
							<CreatableSelect
                                isClearable
                                options={optionsBrand}
                                onInputChange={handleInputChangeBrand}
                                onCreateOption={handleCreateBrand}
                                value={
                                  formState.expense_type && optionsBrand.some(option => option.label === formState.expense_type)
                                      ? optionsBrand.find(option => option.label === formState.expense_type)
                                      : formState.expense_type
                                          ? { value: formState.expense_type, label: formState.expense_type }
                                          : null }
                                menuIsOpen={menuIsOpen}
                                onChange={(selectedOption) => {
                                  const newValue = selectedOption ? selectedOption.label : null;
                                  onChangeField('expense_type', newValue);
                                  if (selectedOption === null) {
                                      onChangeField('expense_type', null);
                                  }
                              }}
                              placeholder="Select or create a brand..."
                            />
						</div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>Today Total Exp Amount *</label>
                            <input type="text" className='form-control' name='expense_total_amount' value={formState.expense_total_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-12'>
                        <div className='form-group'>
                            <label>Note *</label>
                            <textarea 
							className='form-control'
							rows={6} 
							value={formState.expense_note} 
							name='expense_note' 
							onChange={onChangeField}>
							</textarea>  </div>
                    </div>					
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default TeamForm