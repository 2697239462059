import { Table,Image,Select } from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Select as AntSelect } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Link,useParams } from 'react-router-dom';
import ImageUpload from "../Components/ImageUpload"
import moment from 'moment';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useForntNavMenus} from '../hooks/FleetHooks';
import { useMerchantList} from '../hooks/BannerHooks';
import { fleetService } from '../Services/FleetService';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { bannerService,merchantNamesList } from '../Services/BannerService';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE, getConstantData,CUSTOMER_PREFIX,ORDER_PREFIX, ORDER_STATUS_IMG,CATEGORY_IMG} from '../utils/Constants';
import { productService } from '../Services/ProductService';
const initialState = { menu_title: "", menu_image: {server: ""}, menu_image_color: {server: ""},menu_display_order:0,business_type_id:null,menu_merchants:[]}
const CardDetails = (props) => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    let { ticketId } = useParams()
	const [formState, setFormState] = useState({...initialState})
	const [sendEmail,setSendEmail]= useState(false)
	const [sendNotification,setSendNotification]= useState(false)
	const [params, setParams] = useState({paginated:true,limit:10,offset:0,field:"id",sort:"DESC"})
	const [customerData,setCustomerData]= useState([])
    const {fleetData, loading, error, total} = useForntNavMenus(axiosPrivate, params)
	const [merchantList, setMerchantList] = useState(false)
	const {bannerData,loading2} = useMerchantList(axiosPrivate, params)
	console.log(bannerData,"bannerData")
	useEffect(() => {
		if(ticketId && ticketId>0){			
			const apiPromise = fleetService.frontPageNavMenuDetailsById(axiosPrivate, Number(ticketId))
			showPromiseToast(apiPromise, "Please Wait...")
			apiPromise.then(res => {
				if(res.data.frontPageNavMenuDetailsById) {
					setFormState({
						...formState,
						menu_title: res.data.frontPageNavMenuDetailsById.menu_title,
						menu_display_order: res.data.frontPageNavMenuDetailsById.menu_display_order,
						business_type_id: res.data.frontPageNavMenuDetailsById.business_type_id,
						menu_merchants: res.data.frontPageNavMenuDetailsById.menu_merchants
					})
				}
			})		
		}
	  }, [customerData]); // <- add the count variable here
    const render = useMemo(() => {
        const columns = [	 
            {
                title: 'Id',
                dataIndex: 'id'
            },
            {
                title: 'Main Title',
                dataIndex: 'main_menu'
            },				
            {
                title: 'Menu Title',
                dataIndex: 'menu_title'
            },
			 {
                title: 'Menu Display Order',
                dataIndex: 'menu_display_order'
            },
			{
                title: 'Menu Image',
				render: (text, item) => {
					return <Image height={30} src={`${CATEGORY_IMG}/${item.menu_image}`}/>
				}
            },
			{
                title: 'Menu Image Color',
				render: (text, item) => {
					return <Image height={30} src={`${CATEGORY_IMG}/${item.menu_image_color}`}/>
				}
            },
			{
				title: 'Action',
				render: (text, record) => (
					<div className="dropdown dropdown-action text-right">
						<a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
						<div className="dropdown-menu dropdown-menu-right">
							<Link className="dropdown-item" to="#" onClick={(e) => { e.preventDefault(); window.open(`/app-setting/menus-edit/${record.id}`, '_blank'); }} > <i className="fa fa-pencil m-r-5" /> Edit </Link>
						</div>
					</div>
				),
			}
        ]
        return columns
    }, [auth.permissions])

    const onChangeField = (e) => {  
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
	const onBusinessTypeChange = (e) => {
        setFormState({ ...formState, business_type_id: Number(e) })
    }
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.menu_title) {
            showToast("Please enter comment", TOAST_MSG_TYPE.ERROR)
            return
        }
        let paramData = {
            menu_title: formState.menu_title,
			menu_display_order: Number(formState.menu_display_order),
			menu_display_order: Number(formState.menu_display_order),
			business_type_id: Number(formState.business_type_id),
			menu_merchants: formState.menu_merchants,
			id: Number(ticketId)
        }
	    if("local" in formState.menu_image) {
            delete formState.menu_image.local
        }
		if("local" in formState.menu_image_color) {
            delete formState.menu_image_color.local
        }
        const apiPromise = fleetService.addUpdateHomePageMenus(axiosPrivate, paramData,formState.menu_image.server,formState.menu_image_color.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
                showToast(props.crud === "create" ? "Menu Submitted Sucessfully!" : "Menu Submitted Sucessfully", TOAST_MSG_TYPE.SUCCESS)
				navigate(`/app-setting/menus`)
        })
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage App Front Nav Menus</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">App Front Nav Menus</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">App Front Nav Menus</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                   
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= {
									{
									total : total,
									pageSize: 10, 
									showSizeChanger : false,
									itemRender : itemRender 
									} 
								}
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={fleetData}
                            />
                        </div>
                    </div>												
					
					<div className="card-body">
					<div className='row'>
					<div className='col-md-4'>
						<div className='form-group'>
						<label>Select Merchant*</label>
							<Select
							showSearch
							mode="multiple"
							className="select col-md-12"
							value={formState.menu_merchants} // Ensure this is an array of IDs [1, 2, 3]
							options={bannerData?.map(({ business_name, merchant_id }) => ({
							label: business_name,
							value: merchant_id,
							})) || []} // Ensure options have {label, value}
							optionFilterProp="label"
							fieldNames={{ label: "label", value: "value" }} // Match the transformed data
							onChange={(value) => setFormState({ ...formState, menu_merchants: value })} // Update formState
							placeholder="Select merchants"
							/>
						</div>	
					</div>
					<div className='col-md-4'>
						<div className='form-group'>
						<label>Main Store Types*</label>
							<AntSelect 
                                    className='select col-md-12'
                                    value={formState.business_type_id}
                                    options={getConstantData("BUSINESS_TYPE", auth.constants)}
                                    onChange={onBusinessTypeChange}
                                    optionFilterProp={"value"}
                                    fieldNames={{label: "value", value: "id"}}
                                />
						</div>	
					</div>
					<div className='col-md-4'>
						<div className='form-group'>
						<label>Merchant Menu Title*</label>
							<textarea 
							className='form-control'
							rows={1} 
							value={formState.menu_title} 
							name='menu_title' 
							onChange={onChangeField}>
							</textarea>
						</div>	
					</div>	
											
					<div className='col-md-3'>
						<label>Menu display Order*</label>
							<textarea 
							className='form-control'
							rows={1} 
							value={formState.menu_display_order} 
							name='menu_display_order' 
							onChange={onChangeField}>
							</textarea>
						</div>
						<div className="col-sm-3 col-3">
							<label>Menu Image:</label>
							<ImageUpload
							fileName={formState.menu_image.server}
							fileList={
							formState.menu_image.server
								? [
									{
									uid: formState.menu_image,
									url:"local" in formState.menu_image ? `${formState.menu_image.local.url}` : `${ORDER_STATUS_IMG}/${formState.menu_image.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, menu_image: file});
							}}
							onRemove={() => setFormState({...formState, menu_image: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
							<label>Menu Color Image :</label>
							<ImageUpload
							fileName={formState.menu_image_color.server}
							fileList={
							formState.menu_image_color.server
								? [
									{
									uid: formState.menu_image_color,
									url:"local" in formState.menu_image_color ? `${formState.menu_image_color.local.url}` : `${ORDER_STATUS_IMG}/${formState.menu_image_color.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, menu_image_color: file});
							}}
							onRemove={() => setFormState({...formState, menu_image_color: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
						<button className='btn btn-primary mt-40' onClick={submitHandler}>Submit & Save</button>
						</div>
						</div>
					</div>
					</div>
				 </div>
            </div>
    );
}
export default CardDetails