import { useEffect, useReducer } from "react"
import { ACTIONS } from "../utils/Constants"
import { routeService } from "../Services/RouteService"

function cityListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, citiesData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function reviewListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, reviewsData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function couponListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, couponsData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function routeListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, routeData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function logListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, logData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function giftListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, giftCardData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function useCityList(axios, params) {
    const [state, dispatch] = useReducer(cityListReducer, {loading: false, citiesData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.getCitiesList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.cityListing.list, total: res.data.cityListing.total}})
        })
    }, [params])
    return state
}
function useCustomerTableCityList(axios, params) {
    const [state, dispatch] = useReducer(cityListReducer, {loading: false, citiesData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.customerCityListing(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.customerCityListing.list}})
        })
    }, [params])
    return state
}
function useReviewList(axios, params) {
    const [state, dispatch] = useReducer(reviewListReducer, {loading: false, reviewsData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.getReviewList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.productReviewsList.list, total: res.data.productReviewsList.total}})
        })
    }, [params])
    return state
}
function useGiftCardList(axios, params) {
    const [state, dispatch] = useReducer(giftListReducer, {loading: false, giftCardData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.getGiftCardsList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.giftCardListing.list, total: res.data.giftCardListing.total}})
        })
    }, [params])
    return state
}
function useNotificationsList(axios, params) {
    const [state, dispatch] = useReducer(giftListReducer, {loading: false, giftCardData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.notificationListingCMS(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.notificationListingCMS.list, total: res.data.notificationListingCMS.total}})
        })
    }, [params])
    return state
}
function useAdminRewardList(axios, params) {
    const [state, dispatch] = useReducer(giftListReducer, {loading: false, giftCardData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.AdminRewardPointsList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.AdminRewardPointsList.list, total: res.data.AdminRewardPointsList.total}})
        })
    }, [params])
    return state
}
function useCouponList(axios, params) {
    const [state, dispatch] = useReducer(couponListReducer, {loading: false, couponsData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.getCouponsList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.couponListing.list, total: res.data.couponListing.total}})
        })
    }, [params])
    return state
}
function useRouteList(axios, params) {
    const [state, dispatch] = useReducer(routeListReducer, {loading: false, routeData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.getRouteList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.cityRoutesListing.list, total: res.data.cityRoutesListing.total}})
        })
    }, [params])
    return state
}
function useLogList(axios, params) {
    const [state, dispatch] = useReducer(logListReducer, {loading: false, logData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        routeService.getChangeLogs(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.auditLogListCMS.list, total: res.data.auditLogListCMS.total}})
        })
    }, [params])
    return state
}
export {
    useCityList,
	useCouponList,
	useCustomerTableCityList,
	useGiftCardList,
	useNotificationsList,
	useReviewList,
    useRouteList,
    useLogList,
	useAdminRewardList
}