import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useNavigate } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE, getConstantData, API_BASE_URL } from '../utils/Constants';
import { Table,DatePicker,Space } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useOrderReports } from '../hooks/FleetHooks';
import { fleetService } from '../Services/FleetService';

const { RangePicker } = DatePicker;

const FleetList = () => {
	
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
	const [dateRange,setDateRange]=useState(undefined);
    const [params, setParams] = useState({report_name: "PRODUCT_SALES_REPORT",paginated: true, limit: 20, offset: 0, search: "",startDate:"",endDate:""})
    const {fleetData, loading, error, total} = useOrderReports(axiosPrivate, params)
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Id',
                dataIndex: 'product_id'
            },
            {
                title: 'Product Title',
                dataIndex: 'product_title'
            },
			{
                title: 'Variant',
                dataIndex: 'variant'
            },
			{
                title: 'Category',
                dataIndex: 'category_title'
            },
            {
                title: 'Total Sold',
                dataIndex: 'total_sold'
            },
            {
                title: 'Store',
                dataIndex: 'store_name'
            }
        ]
        return columns
    }, [auth.permissions])

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }
	const onChangeRangeDate= (value, dateString) => {
		setDateRange(value)
		setParams({...params, startDate: dateString[0], endDate: dateString[1]})
	}
	console.log("params",params)
    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
	const createLink = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
	const downloadExcel = (e) => {
		e.preventDefault()
        let paramsData={
			is_excel: true,
			report_name: "PRODUCT_SALES_REPORT",
			paginated: false, 
			limit: 20, 
			offset: 0, 
			search: "",
			startDate:params.startDate,
			endDate:params.endDate
		}
        const apiPromise = fleetService.orderReportsData(axiosPrivate, paramsData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.orderReportsData.path) {
				console.log(res.data.orderReportsData)
                const url = `${API_BASE_URL}${res.data.orderReportsData.path.replace("./", "")}`
				console.log(url)
                createLink(url)
            }
        })
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Products Sales</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Products Sales</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active"> Products Sales</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddFleet") ?
                            <div className="col-sm-6">
                                <Link to={"#"} onClick={downloadExcel} className="btn btn-primary btn-width float-right d-block">Download Excel</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
							<div className='col-md-3'>
							<RangePicker 
							    allowClear={true}
                                className="form-control" 
                                onChange={onChangeRangeDate} 
                                value={dateRange ? dateRange :  ""}
                            />
							</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 20, 
                                    itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={fleetData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default FleetList;