import { useEffect, useReducer } from "react"
import { ACTIONS } from "../utils/Constants"
import { fleetService } from "../Services/FleetService"

function fleetListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, fleetData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}
function customerDetailsReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, customer_details: action.payload.data}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useFleetList(axios, params) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.getFleetListing(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.fleetListingCMS.list, total: res.data.fleetListingCMS.total}})
        })
    }, [params])
    return state
}
function useSupportList(axios, params) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.getSupportListing(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.supportTicketListing.list, total: res.data.supportTicketListing.total}})
        })
    }, [params])
    return state
}
function useSupportListDetailsById(axios, params) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.supportTicketDetails(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.supportTicketDetails.list, total: res.data.supportTicketDetails.total}})
        })
    }, [params])
    return state
}
function useForntNavMenus(axios, params) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.frontPageNavMenus(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.frontPageNavMenus.list, total: res.data.frontPageNavMenus.total}})
        })
    }, [params])
    return state
}
function useCustomerDetailsById(axios, customerId) {
    const [state, dispatch] = useReducer(customerDetailsReducer, {loading: false, customer_details: [], error: ""})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.getCustomerByID(axios, customerId).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.customerDetailsByid.customer_details}})
        })
    }, [customerId])
    return state
}
function useOrderReports(axios, params) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.orderReportsData(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.orderReportsData.list, total: res.data.orderReportsData.total ? res.data.orderReportsData.total : 0 }})
        })
    }, [params])
    return state
}
function useInCartProductsReports(axios, params) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.getproductsInCarts(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.productsInCarts.list, total: res.data.productsInCarts.total ? res.data.productsInCarts.total : 0 }})
        })
    }, [params])
    return state
}
function useProductSupplierStocks(axios, params) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        fleetService.ProductSupplierStocks(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.ProductSupplierStocks.list, total: res.data.ProductSupplierStocks.total ? res.data.ProductSupplierStocks.total : 0 }})
        })
    }, [params])
    return state
}

function useOrdersFilterFleet(axios, params, auth) {
    const [state, dispatch] = useReducer(fleetListReducer, {loading: false, fleetData: [], error: "", total: 0})
    useEffect(() => {
        if(auth && auth.permissions.includes("FleetListing")) {
            dispatch({type: ACTIONS.MAKE_REQUEST})
            fleetService.getFleetListing(axios, params).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.fleetListingCMS.list, total: res.data.fleetListingCMS.total}})
            })
        }
        
    }, [params, auth])
    return state
}

export {
    useFleetList,
	useOrderReports,
	useSupportList,
    useOrdersFilterFleet,
	useSupportListDetailsById,
	useInCartProductsReports,
	useCustomerDetailsById,
	useProductSupplierStocks,
	useForntNavMenus
}